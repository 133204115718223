import React, { useEffect } from "react";
import { Provider, useDispatch } from "react-redux";
import { configureStore, createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

const POSTHOG_KEY = "phc_6ED0vYwS2rQYXhaS1ZXsDxcTHCpBbz9YiPCMW6KvC3Y";

posthog.init(POSTHOG_KEY, {
  api_host: "https://app.posthog.com",
});

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (task, { rejectWithValue }) => {
    try {
      const url = window.location.origin.includes("localhost")
        ? "http://localhost:3000"
        : "https://api.react.school";

      const response = await fetch(`${url}/api/profile`, {
        method: "GET",
        credentials: "include",
      });
      const json = await response.json();
      // SIDE-EFFECT: Identify posthog user.
      try {
        if (json) {
          posthog?.identify(json.id, {
            email: json.email,
          });
        }
      } catch (err) {
        // fail gracefully
      }
      return json;
    } catch (err) {
      return rejectWithValue({});
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    status: "idle",
    user: null,
  },
  extraReducers: {
    [fetchUser.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchUser.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.user = action.payload;
    },
    [fetchUser.rejected]: (state, action) => {
      state.status = "rejected";
    },
  },
});

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
  },
});

const AppProvider = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const shouldFetchUser = JSON.parse(localStorage.getItem("fetch_user_info"));
    if (shouldFetchUser) {
      dispatch(fetchUser());
    }
  }, []);
  return children;
};

const Wrapper = ({ element }) => {
  return (
    <PostHogProvider client={posthog}>
      <Provider store={store}>
        <AppProvider>{element}</AppProvider>
      </Provider>
    </PostHogProvider>
  );
};

export const wrapRootElement = Wrapper;
